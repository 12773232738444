.summary {
  width: 100%;
  height: 60px;
}
.summary div {
  width: 32%;
  float: left;
}
.summary div p {
  text-align: center;
  line-height: 30px;
}
.summary div .p2 {
  font-size: 18px;
  font-weight: 600;
  color: #409eff;
}
