






















































































.summary {
  width: 100%;
  height: 60px;
  div {
    width: 32%;
    float: left;
    p {
      text-align: center;
      line-height: 30px;
    }
    .p2 {
      font-size: 18px;
      font-weight: 600;
      color: #409eff;
    }
  }
}
